<template>
    <div class="content-themes">
        <div class="page-sub-box">
            <esp-dx-data-grid
                :data-grid="dataGrid"
                ref="teamGrid"
                @saving="onSaveData"
            ></esp-dx-data-grid>
        </div>
    </div>
</template>

<script>
import {isSuccess} from "@/plugins/common-lib";
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

export default {
    components: {
      EspDxDataGrid,
    },
    props: {
        codeKey: {
            type: String,
            default: ''
        },
    },
    watch: {
    },
    data() {
        return {
            dataGrid: {
                callApi : 'CALL_CC_API',
                refName : 'teamGrid',
                keyExpr:'id',
                allowColumnResizing: true, //컬럼 사이즈 허용
                allowReordering: true,//inline속성 느낌
                showBorders: false, //border 유무
                showColumnHeaders: true, //컬럼 헤더 유무
                showColumnLines: false, //컬럼 세로선 유무
                showRowLines: true, //컬럼 가로선 유무
                rowAlternationEnabled: false,
                repaintChangesOnly: true,
                dataSourceDefaultSortColumn: '+agtteamOrd',
                dataSource: [],
                //height:'calc(100vh - 350px)',    // 주석처리시 100%
                apiActionNm: {
                    delete: 'CC_AGTTEAM_DELETE',
                },
                customEvent: {
                    saving: true,
                    reorder: true,
                },
                showActionButtons:{
                    select:false,
                    copy:false,
                    delete:false,
                    customButtons:[
                    ]
                },
                grouping: {
                    contextMenuEnabled: false,
                    autoExpandAll: false,
                    allowCollapsing: true,
                    expandMode: 'rowClick' // rowClick or buttonClick
                },
                groupPanel: {
                    visible: false,
                },
                //@TODO: 순서변경 버그로 주석
                /*dragging: {
                    sortColumn: 'agtteamOrd',
                    allowReordering: true,
                    dropFeedbackMode: 'push'
                },*/
                columnChooser: {
                    enabled: false, // 컬럼 Chooser 버튼 사용유무
                },
                loadPanel: {
                    enabled: true, // 로딩바 표시 유무
                },
                sorting: {
                    mode: 'multiple' // single multiple
                },
                // scrolling:{ // 미사용시 주석처리
                //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
                // },
                remoteOperations: { // 서버사이드 여부
                    filtering: true,
                    sorting: true,
                    grouping: false,
                    paging: true,
                },
                paging: { // scrolling 미사용시만 적용됨
                    enabled: true,
                    pageSize: 10,//page.size,
                    pageIndex: 0 // 시작페이지
                },
                pager: {
                    visible: true, //페이저 표시 여부
                    showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
                    allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
                    displayMode: "compact", //표시 모드 : ['full', 'compact']
                    showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
                    showNavigationButtons: true //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
                },
                filterRow: {
                    visible: true,
                },
                headerFilter: {
                    visible: true,
                },
                editing: {
                    allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
                    allowDeleting: false,
                    allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
                    changes:[],//null값으로 두면 에러남
                    mode: 'batch', //수정 모드: ['row', 'cell', 'batch'] - batch에서는 inserted 필요없음
                },
                selecting: {
                    mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
                    selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
                    showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
                },
                columns: [
                    {
                        caption: '사이트명',
                        dataField: 'siteId',
                        height: 40,
                        alignment: 'center',
                        visible: true,
                        allowEditing: false,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        fixed: true,
                        lookup: {
                            dataSource: this.$store.getters.getSiteList,
                            displayExpr:"siteNm",
                            valueExpr:"id"
                        },
                    },
                    {
                        caption: '센터명',
                        dataField: 'tenantId',
                        height: 40,
                        alignment: 'center',
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        fixed: true,
                        lookup: {
                            dataSource: this.$store.getters.getTenantList,
                            displayExpr:"tenantNm",
                            valueExpr:"id"
                        },
                        requiredRule: {
                            messege: '센터명은 필수입니다.'
                        },
                    },
                    {
                        caption: '팀ID',
                        dataField: 'id',//:data-field="grid.uniqueKey"
                        height: 40,
                        alignment: 'center',
                        visible: false,
                        allowEditing: false,
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        fixed: true,
                    },
                    {
                        caption: 'CTI 이름',
                        dataField: 'agtteam',
                        height: 40,
                        alignment: 'center',
                        visible: true,
                        allowEditing: false,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        fixed: true,
                    },
                    {
                        caption: 'ERS 이름*',
                        dataField: 'agtteamNm',
                        height: 40,
                        alignment: 'center',
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        fixed: true,
                    },
                    {
                        caption: '순서',
                        dataField: 'agtteamOrd',
                        height: 40,
                        alignment: 'center', // left center right
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none', // asc desc none
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        fixed: true, // 컬럼 fix 시 사용
                    },
                    {
                        caption: '표시여부',
                        dataField: 'viewFl',
                        height: 40,
                        alignment: 'center',
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        repaintChangesOnly: true,
                        fixed: true,
                        lookup: {
                            dataSource: this.$_enums.common.stringViewFlag.values,
                            displayExpr: 'label',
                            valueExpr: 'value',
                        },
                    },
                ]
            },
        }
    },
    computed: {
    },
    methods: {
        /** @description: 데이터 조회 메서드 */
        async selectDataList(sort='+agtteamOrd'){
            let vm = this;

            vm.dataGrid.dataSource = new CustomStore({
                key: 'id',
                async load(loadOptions) {
                    let params = vm.$_getDxDataGridParam(loadOptions);

                    if(!params.sort){
                        params.sort = sort;
                    }

                    let payload = {
                        actionname: 'CC_AGTTEAM_LIST',
                        data: params,
                        loading: false,
                        useErrorPopup: true,
                    }

                    let res = await vm.CALL_CC_API(payload);

                    if( isSuccess(res) ) {
                        let rtnData ={
                            data: res.data.data,//.filter( d => d.agtteamid),
                            totalCount: res.data.header.totalCount,
                        }
                        vm.$refs.teamGrid.totalCount = rtnData.totalCount;
                        return rtnData;
                    }else {
                        return () => { throw 'Data Loading Error'; }
                    }
                }
            });
        },
        /** @description: 데이터 저장 메서드 */
        async onSaveData(e){
            e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

            // 변경된 값이 없으면 메시지 출력
            if (e?.changes.length === 0) {
                this.$_Msg('변경된 값이 없습니다.');
            }

            let data = [];

            e.changes.forEach(d => {
                let keyExpr = this.dataGrid.keyExpr; // ex) id
                let dataKey = d.key; // ex) 1
                let dataMap = d.data; // ex) { value: 100, name: 'test' }

                //표시여부
                if( d.data?.viewFl ){
                    d.data.viewFl = d.data.viewFl === 'Y' ? 'Y' : 'N';
                }

                // 수정/신규/병합 타입 확인 후 데이터 맵에 등록자/수정자 정보 추가
                if (d.type === 'update') {

                    // 병합은 ROW 전체 데이터로 dataMap 설정
                    e.component
                        .byKey(dataKey)
                        .then(data => {
                            dataMap = Object.assign(data, d.data); // Object.assign() 으로 기존 데이터에 변경된 데이터를 덮어씌움
                        })
                        .catch(error => {
                            this.$log.error(error);
                        });
                } else { //추가

                }

                data.push(dataMap); // ex) [{ id: 1, value: 100, name: 'test' }]
            });

            let payload = {
                actionname: 'CC_AGTTEAM_MERGE',
                data: data,
            }

            let res = await this.CALL_CC_API(payload);
            if (isSuccess(res)) {
                this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
                e.component.cancelEditData();
                this.refreshData();
            } else {
                this.$_Msg('API 호출 오류');
                e.component.cancelEditData();
            }
        },
        /** @description : 그리드 refesh 메서드 */
        refreshData() {
            this.$refs.teamGrid.refreshData();
        },
        /** @description: 표시여부 출력 */
        viewFl(rowData){
            if( Number(rowData.viewFl) === 'Y' ){
                return true;
            }else{
                return false;
            }
        },
        /** @description: 라이프 사이클 created시 호출되는 메서드 */
        createdData(){
            this.selectDataList();
        },
    },
    created() {
        this.createdData();
    },
    mounted() {
    },
    destroyed() {

    }
}
</script>

<style scoped>
.container { background-color: #FFF; margin-bottom: 100px; }
.container-top-wrap { margin: 20px 0 10px 0; }
.search-top-wrap > div { display: inline-block; }
.search-top-wrap > div:not(:last-child) { margin-right: 5px; }
.search-top-wrap > div:first-child { margin-right: 20px; }
.container-top-wrap .search-bottom-wrap { margin-top: 30px; }
.search-bottom-wrap .search-bottom-top-wrap { font-size: 0.95em; }

.search-bottom-wrap .search-bottom-bottom-wrap { margin-top: 5px; }
.search-bottom-bottom-wrap {  position: relative; }
.search-bottom-bottom-wrap > div { display: inline-block; }
.search-bottom-bottom-wrap .search-bottom-left-wrap > div { display: inline-block; }
.search-bottom-bottom-wrap .search-bottom-left-wrap > div:not(:first-child) { margin-left: 5px; }

.search-bottom-bottom-wrap .search-bottom-right-wrap { position: absolute; right: 0; }
.search-bottom-bottom-wrap .search-bottom-right-wrap .batch-btn { height: 30px; padding: 0 20px; display: inline-block; border: 1px solid gray; border-radius: 3px; text-align: center; line-height: 28px; cursor: pointer; }

.inner {padding: 0px 21px!important;}
</style>

<style>
.container .dx-pager .dx-pages { text-align: center; float: none; }

.contents-length { color: crimson; }
.updated-cell { background-color: #5dc7f880; }
</style>